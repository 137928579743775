<template>
  <div>
    <FilterOverview title="Flugplanung" :showFilterInitially="false" @reload="onReload">
      <template #toolbar-left>
        <div
          class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
          style="gap: 12px"
        >
          <RegionFilterButtonGroup
            :regions="['EU', 'FERN']"
            :disabled="regionFilterDisabled"
            v-model="selectedRegions"
          ></RegionFilterButtonGroup>

          <b-button-group size="sm" class="d-flex" style="gap: 2px">
            <b-button
              v-for="priority in priorities"
              :key="priority.text"
              :id="priority.text"
              @click="onPriorityClick"
              :variant="
                prioritiesSelected.includes(priority.text)
                  ? 'primary'
                  : prioritiesSelected.length === 0 && priority.text === 'Alle'
                  ? 'primary'
                  : ''
              "
            >
              {{ priority.text }}
            </b-button>
          </b-button-group>

          <RegionsmanagerButtonGroup
            :regionsmanagers="regionsmanagers"
            v-model="selectedRegionsmanager"
          ></RegionsmanagerButtonGroup>

          <b-form-checkbox v-model="vergangeneTermineAusblenden" @change="gridApi.onFilterChanged()" switch>
            Vergangene Termine ausblenden
          </b-form-checkbox>

          <b-form-input
            v-model="fullTextSearchInput"
            placeholder="Suche in Kommentaren"
            size="sm"
            class="ml-4"
            @keyup.enter="handleEnterOnFullTextSearch"
            style="width: 170px"
          ></b-form-input>
          <b-button size="sm ml-2" variant="danger" v-if="isFilterSupplied" @click="resetAllFilters">
            Alle Filter zurücksetzen
          </b-button>
        </div>
      </template>
      <template #table="{ tableHeight }">
        <AgGridVue
          :style="{ height: tableHeight + 80 + 'px' }"
          rowHeight="50px"
          class="ag-theme-alpine m-0 p-0"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowModelType="'serverSide'"
          :statusBar="statusBar"
          :rowSelection="'multiple'"
          :rowMultiSelectWithClick="'true'"
          :tooltipShowDelay="0"
          @rowSelected="onRowSelected"
          @rowDoubleClicked="onRowDoubleClicked"
          @grid-ready="onGridReady"
          suppressCellFocus
        ></AgGridVue>
        <SidebarFlugplanung
          @sidebarHide="onSidebarHide"
          @showModalAufgabeEdit="onShowModalAufgabeEdit"
          @inlandsflugFlugnummerChanged="onFlugnummerChange"
          @rowSelectionChanged="changeRowSelection"
          @singleFlugplanungBearbeitet="onFlugplanungBearbeitet"
          @thWerteBearbeitet="onThWerteBearbeitet"
          :reiseterminId="activeReisetermin?.id"
        />
        <ReisetermineBearbeitenModal
          modalId="EinkaufsdatenBearbeitenModal"
          modalRef="'EinkaufsdatenBearbeitenModalRef'"
          :title="`Einkaufsdaten der Reise ${reisekuerzelSelectedReisetermine} bearbeiten`"
          @handleSubmit="onHandleSubmitReisetermineBearbeitenModal"
          :country="reisekuerzelSelectedReisetermine?.substring(0, 2)"
          @hide="onHideEinkaufsdatenBearbeiten"
          :editOptions="editOptionsBearbeitenModal"
        />
        <ModalInlandsfluegeBearbeiten
          :data="identicalInlandsfluegeSelectedReisetermine"
          @inlandsflugMultipleFlugnummerChanged="onMultipleFlugnummernChanged"
        />
        <AufgabeErstellenModal
          :prefixedVerlinkungen="prefixedVerlinkungen"
          :disableAttachments="false"
          :fromFVC="false"
          :enableManualTask="true"
        />
        <ReisetermineBearbeitenModal
          modalId="ThWerteBearbeitenModal"
          modalRef="ThWerteBearbeitenModalRef"
          :title="`TH-Werte der Reise ${reisekuerzelSelectedReisetermine} bearbeiten`"
          @handleSubmit="onHandleSubmitTHWerteBearbeitenModal"
          :country="reisekuerzelSelectedReisetermine?.substring(0, 2)"
          @hide="onHideTHWerteBearbeiten"
          :editOptions="editOptionsTHWerteBearbeitenModal"
        />
      </template>
      <template #toolbar-right>
        <b-button
          size="sm"
          variant="secondary"
          class="mr-2"
          v-if="isOnlyOneReiseSelected"
          @click="openMultipleBearbeitenModal"
        >
          Einkaufsdaten bearbeiten
        </b-button>
        <b-button size="sm" variant="secondary" class="mr-2" @click="onButtonExport">Terminexport</b-button>

        <b-dropdown right text="..." no-caret size="sm" v-if="allSelectedRows.length > 0">
          <template #button-content><div class="dots-dropdown">...</div></template>
          <b-dropdown-item href="#" @click="openEditTHWertModal" v-if="isOnlyOneReiseSelected">
            TH-Werte bearbeiten
          </b-dropdown-item>
          <b-dropdown-item
            href="#"
            @click="onButtonInlandsfluegeBearbeiten"
            v-if="identicalInlandsfluegeSelectedReisetermine?.length > 0"
          >
            Inlandsflüge bearbeiten
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="openAufgabenModalWithData">Aufgabe erstellen</b-dropdown-item>
        </b-dropdown>
      </template>
    </FilterOverview>
  </div>
</template>

<script>
import buildQuery from 'odata-query';

import regionsmanagerConfig from '@/core/flugverfuegbarkeit/regionsmanager-reisekuerzel.json';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-enterprise';
import CustomHeader from '@/components/flugverfuegbarkeit/custom-header.vue';
import FilterOverview from '@/components/common/filter-overview.vue';
import AgDateRangePicker from '@/components/flugverfuegbarkeit/ag-date-range-picker.vue';
import { format, sub, parseISO, getISODay } from 'date-fns';
import StatusBarComponent from '@/components/flugverfuegbarkeit/status-bar-ag-grid.vue';
import * as odataService from '@/core/common/services/odata.service';
import { add } from 'date-fns';
import { utils, writeFileXLSX } from 'xlsx';
import { mapState, mapGetters } from 'vuex';
import SidebarFlugplanung from '@/components/produkte/reisetermine/sidebar-flugplanung.vue';
import {
  fernRegions,
  euRegions,
  formatDateWithoutHours,
  reiseterminStateFormatter,
} from '@/core/common/helpers/utils.js';
import {
  flugplanungSelectedReiseterminVariables,
  flugplanungExpandConfig,
  flugplanungHeaderRow,
} from '@/core/common/helpers/exportHelper.js';
import ReisetermineBearbeitenModal from '@/components/produkte/reisetermine/reisetermine-bearbeiten-modal.vue';
import { einkaufsartOptions, statusgruppeOptions } from '@/core/common/helpers/utils.js';
import apiService from '@/core/common/services/api.service';
import FilterRendererInlandsflugEinkauf from '@/components/common/filter-renderer-inlandsflug-einkauf.vue';
import BeschreibungTooltip from '@/components/aufgaben/beschreibung-tooltip.vue';
import ModalInlandsfluegeBearbeiten from '@/components/produkte/reisetermine/modal-inlandsfluege-bearbeiten.vue';
import AufgabeErstellenModal from '@/components/aufgaben/aufgabe-erstellen-modal.vue';
import CellRendererAufgaben from '@/components/produkte/reisetermine/cell-renderer-aufgaben.vue';
import RegionFilterButtonGroup from '@/components/common/region-filter-button-group.vue';
import RegionsmanagerButtonGroup from '@/components/common/regionsmanager-filter-button-group.vue';
import { GET_FILTER_OPTIONS } from '@/core/produkte/reisetermine/stores/reisetermine.module';
import RangeNumberFilterCustomHeader from '@/components/flugverfuegbarkeit/custom-header-range-number-filter.vue';
import CellRendererPax from '@/components/produkte/reisetermine/cell-renderer-pax.vue';
import { flugplanungAufgabenKategorien } from '@/core/common/helpers/utils.js';
import { vorzeitigGarantiertClass } from '@/core/common/helpers/utils';

export default {
  components: {
    FilterOverview,
    AgDateRangePicker,
    AgGridVue,
    StatusBarComponent,
    SidebarFlugplanung,
    agColumnHeader: CustomHeader,
    ReisetermineBearbeitenModal,
    BeschreibungTooltip,
    FilterRendererInlandsflugEinkauf,
    ModalInlandsfluegeBearbeiten,
    AufgabeErstellenModal,
    CellRendererAufgaben,
    RegionFilterButtonGroup,
    RegionsmanagerButtonGroup,
    RangeNumberFilterCustomHeader: RangeNumberFilterCustomHeader,
    CellRendererPax,
  },
  data() {
    return {
      lastOdataFilter: null,
      prefixedVerlinkungen: [],
      isFilterSupplied: false,
      editOptionsTHWerteBearbeitenModal: [
        {
          label: 'TH Langstrecke',
          key: 'thLangstrecke',
          type: 'input',
          value: null,
          isDefault: true,
        },
        {
          label: 'TH Inlandsflug',
          key: 'thInlandsflug',
          type: 'input',
          value: null,
          isDefault: true,
        },
      ],
      editOptionsBearbeitenModal: [
        {
          label: 'Status Gruppe',
          key: 'statusgruppe',
          type: 'select',
          selectOptions: statusgruppeOptions,
          value: null,
        },
        {
          label: 'Einkaufsart',
          key: 'einkaufsart',
          type: 'select',
          selectOptions: einkaufsartOptions,
          value: null,
        },
        { label: 'GRP PNR', key: 'gruppePnr', type: 'input', value: null },
        { label: 'Eingekauft PAX', key: 'eingekauftePax', type: 'number', value: null },
        { label: 'Mindestabnahme Airline', key: 'mindestabnahmeAirline', type: 'number', value: null },
        { label: 'GRP Flughafen', key: 'gruppeFlughafenIataCode', type: 'flughafen', value: null },
        { label: 'Flugnr. GRP Hin', key: 'flugnummerGruppeHin', type: 'input', value: null },
        { label: 'Flugnr. GRP Rück', key: 'flugnummerGruppeRueck', type: 'input', value: null },
        { label: 'OB Class', key: 'obClass', type: 'input', value: null },
        { label: 'IB Class', key: 'ibClass', type: 'input', value: null },
        { label: 'Optionsfrist', key: 'optionsfrist', type: 'number', value: null },
        { label: 'Ticketfrist', key: 'ticketfrist', type: 'number', value: null },
        { label: 'Preis Netto', key: 'hauptstreckePreisNetto', type: 'number', value: null },
        { label: '€-Summe aller INFL', key: 'inlandsflugPreis', type: 'number', value: null },
        { label: 'Taxes', key: 'taxesFlug', type: 'number', value: null },
      ],
      selectedRowID: null,
      columnDefs: null,
      gridApi: null,
      statusBar: null,
      fullTextSearchInput: null,
      gridColumnApi: null,
      activeReisetermin: null,
      allSelectedRows: [],
      count: null,
      odataFilterReiseterminkuerzel: null,
      odataFilterReisekuerzel: null,
      defaultColDef: {
        flex: 1,
        filter: true,
        sortable: false,
        resizable: false,
        floatingFilter: true,
        suppressMenu: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        filterParams: {
          defaultToNothingSelected: true,
        },
      },
      regionFilterDisabled: true,
      priorities: [
        { value: null, text: 'Alle' },
        { value: 0, text: 'A' },
        { value: 1, text: 'B' },
        { value: 2, text: 'C' },
        { value: 3, text: 'D' },
      ],
      prioritiesSelected: [],
      selectedRegions: [],
      vergangeneTermineAusblenden: true,
      regionsmanagers: ['Alle', 'STGE', 'SYKE'],
      selectedRegionsmanager: [],
      defaultStatusFilter: [
        'Vorgeschlagen',
        'Aufgelegt',
        'Anlagebereit',
        'CmBestaetigt',
        'CmAngefragt',
        'Abgesagt',
      ],
    };
  },
  watch: {
    // schaut auf das array "selectedRegions" und dessen veränderung
    selectedRegions(newSelectedRegions) {
      // und geben das newSelectedRegions aus dem emit in die methode
      this.changeRegionFilterInstance(newSelectedRegions);
    },
    selectedRegionsmanager() {
      this.gridApi.onFilterChanged();
    },
  },
  created() {
    this.statusBar = {
      statusPanels: [{ statusPanel: 'StatusBarComponent', key: 'statusBarCompKey', align: 'left' }],
    };
    this.columnDefs = [
      {
        headerName: '',
        children: [
          {
            field: 'istZusatztermin',
            cellRenderer: params => {
              if (params.value) {
                return 'Z';
              }
              return '';
            },
            filterParams: {
              values: [true, false],
              valueFormatter: params =>
                params.value === true
                  ? 'Zusatztermin'
                  : params.value === false
                  ? 'Kein Zusatztermin'
                  : params.value,
            },
            headerName: '',
            pinned: 'left',
            width: 50,
            minWidth: 50,
          },
          {
            field: 'inlandsflugEinkauf',
            headerName: '',
            minWidth: 55,
            width: 55,
            filter: 'agSetColumnFilter',
            pinned: 'left',
            filterParams: {
              values: ['NurSkr', 'NurDmc', 'Gemischt', 'KeinInlandsflug'],
              cellRenderer: 'FilterRendererInlandsflugEinkauf',
              debounceMs: 1000,
              comparator: (a, b) => {
                const ranking = {
                  KeinInlandsflug: 0,
                  NurSkr: 1,
                  NurDmc: 2,
                  Gemischt: 3,
                };

                if (a === 'null') return 1;
                else if (ranking[a] === ranking[b]) return 0;
                return ranking[a] > ranking[b] ? 1 : -1;
              },
            },
            cellRenderer: params => {
              const { inlandsfluege } = params.data.metadata;
              const nurSkrInlandsfluege = inlandsfluege.every(inlandsflug => inlandsflug.einkauf === 'Skr');
              const nurDmcInlandsfluege = inlandsfluege.every(inlandsflug => inlandsflug.einkauf === 'Dmc');
              const skrOderDmcInlandsfluege = inlandsfluege.every(
                inlandsflug => inlandsflug.einkauf === 'Dmc' || inlandsflug.einkauf === 'Skr'
              );
              if (inlandsfluege.length === 0) return '';
              else if (nurSkrInlandsfluege) return '<i class="fas fa-plane text-danger"></i>';
              else if (nurDmcInlandsfluege) return '<i class="fas fa-plane" style="color: #000"></i>';
              else if (skrOderDmcInlandsfluege) return '<i class="fas fa-plane" style="color: #A6A6A8"></i>';
            },
            floatingFilter: true,
            resizable: false,
          },
          {
            checkboxSelection: true,
            headerCheckboxSelection: true,
            field: 'reiseterminkuerzel',
            headerName: 'Reise',
            sortable: true,
            filter: 'agSetColumnFilter',
            filterParams: {
              buttons: ['apply'],
              debounceMs: 1000,
              values: async params => {
                const lastOdataFilterBuilt = buildQuery({
                  filter: this.lastOdataFilter,
                  select: ['reiseterminkuerzel'],
                });
                const query = {
                  odataFilter: lastOdataFilterBuilt,
                };
                const result = await this.$store.dispatch('overview/' + GET_FILTER_OPTIONS, query);
                const options = result.map(item => item.reiseterminkuerzel.substring(0, 6));
                const uniqueOptions = [...new Set(options)];

                params.success(uniqueOptions);
              },
            },
            valueFormatter: params => params.value.substring(0, 6),
            floatingFilter: true,
            width: 130,
            minWidth: 130,
            pinned: 'left',
          },
          {
            field: 'garantiert',
            headerName: 'Gar.',
            minWidth: 50,
            width: 50,
            cellRenderer: params => {
              return params.value
                ? "<i class='fas fa-check text-success fa-lg'></i>"
                : "<i class='ml-1 fas fa-times text-danger fa-lg'></i>";
            },
            filter: 'agSetColumnFilter',
            filterParams: {
              values: [true, false],
              cellRenderer: params =>
                params.value === '(Select All)'
                  ? params.value
                  : params.value
                  ? "<i class='fas fa-check text-success fa-lg'/>"
                  : "<i class='ml-1 fas fa-times text-danger fa-lg'/>",
              debounceMs: 1000,
            },
            pinned: 'left',
          },
          {
            field: 'vorzeitigGarantiert',
            headerName: 'Vorz. gar.',
            minWidth: 85,
            maxWidth: 85,
            cellRenderer: params => {
              const iconClass = this.vorzeitigGarantiertClass(params.value);
              return `<i class="${iconClass}"></i>`;
            },
            filter: 'agSetColumnFilter',
            filterParams: {
              values: ['VorzeitigGarantiert', 'WarVorzeitigGarantiert', 'NichtVorzeitigGarantiert'],
              valueFormatter: params =>
                params.value === 'VorzeitigGarantiert'
                  ? 'Vorzeitig garantiert'
                  : params.value === 'WarVorzeitigGarantiert'
                  ? 'War vorzeitig garantiert'
                  : params.value === 'NichtVorzeitigGarantiert'
                  ? 'Nicht vorzeitig garantiert'
                  : params.value,
              debounceMs: 1000,
            },
            pinned: 'left',
          },
          {
            field: 'pax.ist',
            headerName: 'PAX',
            headerComponent: 'RangeNumberFilterCustomHeader',
            cellRenderer: 'CellRendererPax',
            filter: 'agTextColumnFilter',
            filterParams: {
              buttons: ['apply'],
            },
            minWidth: 165,
            width: 165,
            suppressRowTransform: true,
            cellStyle: {
              'line-height': 'normal',
              display: 'flex',
              'justify-items': 'center',
              overflow: 'visible',
              'align-items': 'center',
              'padding-bottom': '10px',
            },
            cellRendererParams: params => ({
              paxObject: params.data.pax,
              minPax: params.data.metadata.minPax,
              maxPax: params.data.metadata.maxPax,
              kalkPax: params.data.metadata.kalkPax,
              erwartetePax: params.data.metadata.erwartetePax,
              durchfuehrbareMinPax: params.data.metadata.durchfuehrbareMinPax,
              id: params.data.id,
            }),
            pinned: 'left',
          },
          {
            field: 'abreisedatum',
            headerName: 'Abreise',
            filter: 'AgDateRangePicker',
            floatingFilterComponent: 'AgDateRangePicker',
            floatingFilterComponentParams: {
              isChild: true,
            },
            cellRenderer: params => (params.value ? format(new Date(params.value), 'dd.MM.yyyy') : '-'),
            width: 150,
            minWidth: 150,
            pinned: 'left',
          },
          {
            field: 'hotelendUndRueckflugdatum',
            headerName: 'Rückflugdatum',
            filter: 'AgDateRangePicker',
            floatingFilterComponent: 'AgDateRangePicker',
            floatingFilterComponentParams: {
              isChild: true,
            },
            cellRenderer: params => (params.value ? format(new Date(params.value), 'dd.MM.yyyy') : '-'),
            width: 150,
            minWidth: 150,
          },
          {
            field: 'ziellandZielflughaefen',
            headerName: 'ZL Zielflughäfen',
            cellRenderer: params => {
              const { ziellandZielflughaefen } = params.data.metadata;
              return ziellandZielflughaefen?.map(fh => fh.flughafenIataCode).join('/') ?? '';
            },
            //filterParams: { values: this.alleFlughaefenIataCode },
            filter: 'agSetColumnFilter',
            filterParams: {
              debounceMs: 1000,
              values: async params => {
                const lastOdataFilterBuilt = buildQuery({
                  filter: this.lastOdataFilter,
                  expand: {
                    metadata: {
                      select: ['id'],
                      expand: {
                        ziellandZielflughaefen: {
                          select: ['flughafenIataCode'],
                        },
                      },
                    },
                  },
                  select: ['id'],
                });
                const query = {
                  odataFilter: lastOdataFilterBuilt,
                };
                const result = await this.$store.dispatch('overview/' + GET_FILTER_OPTIONS, query);
                const options = result
                  .map(item => item.metadata.ziellandZielflughaefen.map(item => item.flughafenIataCode))
                  .flat();
                const uniqueOptions = [...new Set(options)];

                params.success(uniqueOptions);
              },
            },
            minWidth: 125,
            width: 125,
          },
          {
            field: 'ziellandAbflughaefen',
            headerName: 'ZL Abflughäfen',
            cellRenderer: params => {
              const { ziellandAbflughaefen } = params.data.metadata;
              return ziellandAbflughaefen?.map(fh => fh.flughafenIataCode).join('/') ?? '';
            },
            filter: 'agSetColumnFilter',
            filterParams: {
              debounceMs: 1000,
              values: async params => {
                const lastOdataFilterBuilt = buildQuery({
                  filter: this.lastOdataFilter,
                  expand: {
                    metadata: {
                      select: ['id'],
                      expand: {
                        ziellandAbflughaefen: {
                          select: ['flughafenIataCode'],
                        },
                      },
                    },
                  },
                  select: ['id'],
                });
                const query = {
                  odataFilter: lastOdataFilterBuilt,
                };
                const result = await this.$store.dispatch('overview/' + GET_FILTER_OPTIONS, query);
                const options = result
                  .map(item => item.metadata.ziellandAbflughaefen.map(item => item.flughafenIataCode))
                  .flat();
                const uniqueOptions = [...new Set(options)];

                params.success(uniqueOptions);
              },
            },
            minWidth: 125,
            width: 125,
          },
          {
            field: 'state',
            headerName: 'Status',
            filter: 'agSetColumnFilter',
            floatingFilter: true,
            filterParams: {
              valueFormatter: params => reiseterminStateFormatter[params.value],
              values: Object.keys(reiseterminStateFormatter),
              debounceMs: 1000,
            },
            valueFormatter: params => reiseterminStateFormatter[params.value],
            width: 150,
            minWidth: 150,
          },
          {
            field: 'flugeinkauf.statusgruppe',
            headerName: 'Status Gruppe',
            filter: 'agSetColumnFilter',
            minWidth: 150,
            width: 150,
            filterParams: {
              debounceMs: 1000,
              values: async params => {
                const lastOdataFilterBuilt = buildQuery({
                  filter: this.lastOdataFilter,
                  expand: {
                    flugeinkauf: {
                      select: ['statusgruppe'],
                    },
                  },
                  select: ['id'],
                });
                const query = { odataFilter: lastOdataFilterBuilt };
                const result = await this.$store.dispatch('overview/' + GET_FILTER_OPTIONS, query);
                const options = result
                  .map(item => item.flugeinkauf?.statusgruppe)
                  .filter(value => value !== undefined);
                const uniqueOptions = [...new Set(options)];

                params.success(uniqueOptions);
              },
              valueFormatter: params =>
                params.value ? statusgruppeOptions.find(option => option.value === params.value)?.text : '-',
            },
            cellRenderer: params =>
              params.value ? statusgruppeOptions.find(option => option.value === params.value)?.text : '-',
          },
          {
            field: 'flugeinkauf.einkaufsart',
            headerName: 'Einkaufsart',
            minWidth: 120,
            width: 120,
            filter: 'agSetColumnFilter',
            floatingFilter: true,
            filterParams: {
              debounceMs: 1000,
              values: async params => {
                const lastOdataFilterBuilt = buildQuery({
                  filter: this.lastOdataFilter,
                  expand: {
                    flugeinkauf: {
                      select: ['einkaufsart'],
                    },
                  },
                  select: ['id'],
                });
                const query = { odataFilter: lastOdataFilterBuilt };
                const result = await this.$store.dispatch('overview/' + GET_FILTER_OPTIONS, query);
                const options = result
                  .map(item => item.flugeinkauf?.einkaufsart)
                  .filter(value => value !== undefined);
                const uniqueOptions = [...new Set(options)];

                params.success(uniqueOptions);
              },
              valueFormatter: params =>
                params.value ? einkaufsartOptions.find(option => option.value === params.value)?.text : '-',
            },
            cellRenderer: params =>
              params.value ? einkaufsartOptions.find(option => option.value === params.value)?.text : '-',
            tooltipValueGetter: params =>
              params.value ? einkaufsartOptions.find(option => option.value === params.value)?.text : '-',
          },
          {
            field: 'stopSale',
            headerName: 'Stop Sale',
            minWidth: 80,
            width: 80,
            cellRenderer: params =>
              params.data.stopSale
                ? '<i class="flaticon2-exclamation mr-1 text-white rounded bg-danger"/>'
                : '-',
            filter: 'agSetColumnFilter',
            filterParams: {
              values: [true, false],
              cellRenderer: params =>
                params.value === '(Select All)'
                  ? params.value
                  : params.value
                  ? '<i class="flaticon2-exclamation mr-1 text-white rounded bg-danger"/>'
                  : '-',
            },
          },
          {
            field: 'metadata.overnightOutbound',
            headerName: 'OB',
            cellRenderer: params => (params.value === null ? '-' : params.value),
            minWidth: 70,
            width: 70,
            filterParams: {
              values: [0, 1, 2],
            },
          },
          {
            field: 'metadata.overnightInbound',
            headerName: 'IB',
            cellRenderer: params => (params.value === null ? '-' : params.value),
            minWidth: 70,
            width: 70,
            filterParams: {
              values: [0, 1, 2],
            },
          },
          {
            field: 'startdatum',
            headerName: 'Startdatum',
            filter: 'AgDateRangePicker',
            floatingFilterComponent: 'AgDateRangePicker',
            floatingFilterComponentParams: {
              isChild: true,
            },
            cellRenderer: params => (params.value ? format(new Date(params.value), 'dd.MM.yyyy') : '-'),
            width: 150,
            minWidth: 150,
          },
          {
            field: 'flugeinkauf.gruppePnr',
            headerName: 'GRP PNR',
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['apply'] },
            floatingFilter: true,
            cellRenderer: params => params.value ?? '-',
            minWidth: 100,
            width: 100,
          },
        ],
      },
      {
        headerName: 'Hinflug',
        headerClass: 'hinflug-header',
        children: [
          {
            field: 'flugeinkauf.flugnummerGruppeHin',
            headerName: 'Flugnr. GRP',
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            cellRenderer: params => params.value ?? '-',
            minWidth: 100,
            width: 100,
          },
          {
            field: 'verkehrstagHin',
            headerName: 'VT',
            filter: 'agSetColumnFilter',
            cellRenderer: params => params.value ?? '-',
            // floatingFilter: true,
            filterParams: {
              debounceMs: 1000,
              values: async params => {
                const lastOdataFilterBuilt = buildQuery({
                  filter: this.lastOdataFilter,
                  select: ['id', 'verkehrstagHin'],
                });
                const query = { odataFilter: lastOdataFilterBuilt };
                const result = await this.$store.dispatch('overview/' + GET_FILTER_OPTIONS, query);
                const options = result.map(vt => vt.verkehrstagHin);
                const uniqueOptions = [...new Set(options)];

                params.success(uniqueOptions);
              },
            },
            minWidth: 60,
            width: 60,
          },
          {
            field: 'metadata.fruehesteAnkunft',
            headerName: 'Früh. Ankunft',
            filter: false,
            cellRenderer: params => (params.value ? params.value.substring(0, 5) + ' Uhr' : '-'),
            minWidth: 120,
            width: 120,
          },
          {
            field: 'metadata.spaetesteAnkunft',
            headerName: 'Spät. Ankunft',
            filter: false,
            cellRenderer: params =>
              params.value
                ? `${params.value.substring(0, 5)} Uhr ${
                    params.data.metadata.restriktionenHinRollover ? '+1' : ''
                  }`
                : '-',
            minWidth: 120,
            width: 120,
          },
        ],
      },
      {
        headerName: 'Rückflug',
        headerClass: 'rueckflug-header',
        children: [
          {
            field: 'flugeinkauf.flugnummerGruppeRueck',
            headerName: 'Flugnr GRP',
            filter: 'agTextColumnFilter',
            cellRenderer: params => params.value ?? '-',
            floatingFilter: true,
            minWidth: 80,
            width: 80,
          },
          {
            field: 'verkehrstagRueck',
            headerName: 'VT',
            filter: 'agSetColumnFilter',
            filterParams: {
              debounceMs: 1000,
              values: async params => {
                const lastOdataFilterBuilt = buildQuery({
                  filter: this.lastOdataFilter,
                  select: ['id', 'verkehrstagRueck'],
                });
                const query = { odataFilter: lastOdataFilterBuilt };
                const result = await this.$store.dispatch('overview/' + GET_FILTER_OPTIONS, query);
                const options = result.map(vt => vt.verkehrstagRueck);
                const uniqueOptions = [...new Set(options)];

                params.success(uniqueOptions);
              },
            },
            cellRenderer: params => params.value ?? '-',
            floatingFilter: true,
            minWidth: 60,
            width: 60,
          },
          {
            field: 'metadata.fruehesterAbflug',
            headerName: 'Früh. Abflug',
            filter: false,
            cellRenderer: params => (params.value ? params.value.substring(0, 5) + ' Uhr' : '-'),
            minWidth: 120,
            width: 120,
          },
          {
            field: 'metadata.spaetesterAbflug',
            headerName: 'Spät. Abflug',
            filter: false,
            cellRenderer: params =>
              params.value
                ? `${params.value.substring(0, 5)} Uhr ${
                    params.data.metadata.restriktionenRueckRollover ? '+1' : ''
                  }`
                : '-',
            minWidth: 120,
            width: 120,
          },
        ],
      },
      {
        headerName: '',
        children: [
          {
            field: 'enddatum',
            headerName: 'Ankunft DE',
            filter: 'AgDateRangePicker',
            floatingFilterComponent: 'AgDateRangePicker',
            floatingFilterComponentParams: {
              isChild: true,
            },
            cellRenderer: params => (params.value ? format(new Date(params.value), 'dd.MM.yyyy') : '-'),
            minWidth: 150,
            width: 150,
          },
          {
            field: 'metadata.transferszenarioHin',
            headerName: 'TS Hin',
            filter: 'agSetColumnFilter',
            floatingFilter: true,
            filterParams: {
              values: async params => {
                const lastOdataFilterBuilt = buildQuery({
                  filter: this.lastOdataFilter,
                  expand: {
                    metadata: {
                      select: ['transferszenarioHin'],
                    },
                  },
                  select: ['id'],
                });
                const query = { odataFilter: lastOdataFilterBuilt };
                const result = await this.$store.dispatch('overview/' + GET_FILTER_OPTIONS, query);
                const options = result
                  .map(item => item.metadata?.transferszenarioHin)
                  .filter(value => value !== undefined);
                const uniqueOptions = [...new Set(options)];

                params.success(uniqueOptions);
              },
              valueFormatter: params => {
                if (params.value === 'T5_2' || params.value === 'T5_3') {
                  return params.value;
                }
                return params.value?.substring(0, 2);
              },
              debounceMs: 1000,
              comparator: (a, b) => {
                const order = [
                  'T1_1inkl',
                  'T2_1inkl_exkl_mögl',
                  'T3_unbegr_inkl',
                  'T4_unbegr_exkl',
                  'T5_fest_inkl',
                  'T5_2',
                  'T5_3',
                  'T6',
                  'TX_ohne',
                  'TS',
                ];

                if (!a) return 1;
                if (!b) return -1;

                const indexA = order.indexOf(a);
                const indexB = order.indexOf(b);

                if (indexA === -1) return 1;
                if (indexB === -1) return -1;

                return indexA - indexB;
              },
            },
            cellRenderer: params => {
              if (params.value === 'T5_2' || params.value === 'T5_3') {
                return params.value;
              }
              return params.value?.substring(0, 2);
            },
            width: 70,
            minWidth: 70,
          },
          {
            field: 'metadata.transferszenarioRueck',
            headerName: 'TS Rück',
            filter: 'agSetColumnFilter',
            filterParams: {
              values: async params => {
                const lastOdataFilterBuilt = buildQuery({
                  filter: this.lastOdataFilter,
                  expand: {
                    metadata: {
                      select: ['transferszenarioRueck'],
                    },
                  },
                  select: ['id'],
                });
                const query = { odataFilter: lastOdataFilterBuilt };
                const result = await this.$store.dispatch('overview/' + GET_FILTER_OPTIONS, query);
                const options = result
                  .map(item => item.metadata?.transferszenarioRueck)
                  .filter(value => value !== undefined);
                const uniqueOptions = [...new Set(options)];

                params.success(uniqueOptions);
              },
              valueFormatter: params => {
                if (params.value === 'T5_2' || params.value === 'T5_3') {
                  return params.value;
                }
                return params.value?.substring(0, 2);
              },
              debounceMs: 1000,
              comparator: (a, b) => {
                const order = [
                  'T1_1inkl',
                  'T2_1inkl_exkl_mögl',
                  'T3_unbegr_inkl',
                  'T4_unbegr_exkl',
                  'T5_fest_inkl',
                  'T5_2',
                  'T5_3',
                  'T6',
                  'TX_ohne',
                  'TS',
                ];

                if (!a) return 1;
                if (!b) return -1;

                const indexA = order.indexOf(a);
                const indexB = order.indexOf(b);

                if (indexA === -1) return 1;
                if (indexB === -1) return -1;

                return indexA - indexB;
              },
            },
            floatingFilter: true,
            cellRenderer: params => {
              if (params.value === 'T5_2' || params.value === 'T5_3') {
                return params.value;
              }
              return params.value?.substring(0, 2);
            },
            width: 70,
            minWidth: 70,
          },
          {
            field: 'flugeinkauf.hauptstreckePreisNetto',
            headerName: 'Preis Netto',
            filter: 'agTextColumnFilter',
            headerComponent: 'RangeNumberFilterCustomHeader',
            filterParams: { buttons: ['apply'] },
            cellRenderer: params => (params.value ? params.value + '€' : '-'),
            minWidth: 115,
            width: 115,
          },
          {
            field: 'flugeinkauf.inlandsflugPreis',
            headerName: '€-Summe aller INFL',
            headerComponent: 'RangeNumberFilterCustomHeader',
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['apply'] },
            cellRenderer: params => (params.value ? params.value + '€' : '-'),
            minWidth: 180,
            width: 180,
          },
          {
            field: 'flugeinkauf.taxesFlug',
            headerName: 'Taxes',
            headerComponent: 'RangeNumberFilterCustomHeader',
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['apply'] },
            cellRenderer: params => (params.value ? params.value + '€' : '-'),
            minWidth: 80,
            width: 80,
          },
          {
            field: 'flugeinkauf.gesamtpreisFlugeinkauf',
            headerName: 'Gesamtpreis GRP',
            headerComponent: 'RangeNumberFilterCustomHeader',
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['apply'] },
            cellRenderer: params => (params.value != null ? Math.round(params.value) + '€' : '-'),
            minWidth: 160,
            width: 160,
          },
          {
            field: 'reise.region',
            hide: true,
            filter: 'agSetColumnFilter',
            filterParams: {
              values: ['EU', 'Fern'],
              debounceMs: 1000,
            },
          },
          {
            field: 'thLangstrecke',
            headerName: 'TH Langstrecke',
            headerComponent: 'RangeNumberFilterCustomHeader',
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['apply'] },
            cellRenderer: params => (params.value ? params.value + '€' : '-'),
            width: 145,
            minWidth: 145,
          },
          {
            field: 'thInlandsflug',
            headerName: 'TH Inlandsflug',
            headerComponent: 'RangeNumberFilterCustomHeader',
            cellRenderer: params => (params.value ? params.value + '€' : '-'),
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['apply'] },
            width: 140,
            minWidth: 140,
          },
          {
            field: 'gueltigerSaisonpreis.thMaxLangstrecke',
            headerName: 'TH Max Langstrecke',
            headerComponent: 'RangeNumberFilterCustomHeader',
            cellRenderer: params => (params.value ? params.value + '€' : '-'),
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['apply'] },
            width: 175,
            minWidth: 175,
          },
          {
            field: 'gueltigerSaisonpreis.thMaxInlandsflug',
            headerName: 'TH Max Inlandsflug',
            headerComponent: 'RangeNumberFilterCustomHeader',
            cellRenderer: params => (params.value ? params.value + '€' : '-'),
            filter: 'agTextColumnFilter',
            filterParams: { buttons: ['apply'] },
            width: 175,
            minWidth: 175,
          },
          {
            field: 'ticketfristdatumFlugeinkauf',
            headerName: 'Ticketdatum',
            filter: 'AgDateRangePicker',
            floatingFilterComponent: 'AgDateRangePicker',
            floatingFilterComponentParams: {
              isChild: true,
            },
            cellRenderer: params => (params.value ? format(new Date(params.value), 'dd.MM.yyyy') : '-'),
            width: 150,
            minWidth: 150,
          },
          {
            field: 'optionsdatumFlugeinkauf',
            headerName: 'Optionsdatum',
            filter: 'AgDateRangePicker',
            floatingFilterComponent: 'AgDateRangePicker',
            floatingFilterComponentParams: {
              isChild: true,
            },
            cellRenderer: params => (params.value ? format(new Date(params.value), 'dd.MM.yyyy') : '-'),
            minWidth: 150,
            width: 150,
          },
          {
            field: 'aufgaben',
            headerName: 'Option bearbeitet',
            cellRenderer: 'CellRendererAufgaben',
            filter: 'agSetColumnFilter',
            filterParams: {
              refreshValuesOnOpen: true,
              values: params => {
                const labels = this.getAufgabenKategorienLabels.map(item => item.label);
                labels.push('Nicht Optionsfrist erreicht');
                params.success(labels);
              },
              cellRenderer: params => {
                return params.value === 'Nicht Optionsfrist erreicht'
                  ? `<s>Optionsfrist erreicht</s>`
                  : params.value;
              },

              debounceMs: 500,
            },
            cellRendererParams: params => ({
              data: params.data,
              aufgaben: params.data.aufgaben,
              filter: 'flugplanung',
              ansicht: 'flugplanung-overview',
            }),
            minWidth: 550,
            width: 550,
            cellStyle: {
              display: 'flex',
              alignItems: 'center',
            },
          },
          {
            field: 'fullTextSearch',
            filter: 'agTextColumnFilter',
            hide: true,
          },
        ],
      },
    ];
  },
  computed: {
    ...mapGetters(['currentUserId', 'getReisekuerzelOptions', 'getKategorienLabel']),
    ...mapState({
      reisenOfRegionsmanagers: state => state.reisen.regionsmanager,
      alleFlughaefen: state => state.flughaefen.flughaefen,
    }),
    alleFlughaefenIataCode() {
      return this.alleFlughaefen?.map(flughafen => flughafen.iataCode);
    },
    reisenOptions() {
      return this.getReisekuerzelOptions.map(r => r.reisekuerzel);
    },
    // gibt als label den Lesbaren und als value den internen Wert zurück
    getAufgabenKategorienLabels() {
      return flugplanungAufgabenKategorien.map(kategorie => ({
        label: this.getKategorienLabel(kategorie),
        value: kategorie,
      }));
    },
    // es wird eine Zuordnung erstellt, die die Labels mit den internen Werten "verbindet"
    aufgabenLabelToValueMap() {
      const map = {};
      this.getAufgabenKategorienLabels.forEach(item => {
        map[item.label] = item.value;
      });
      map['Nicht Optionsfrist erreicht'] = 'nichtOptionsfristErreicht';
      return map;
    },
    identicalInlandsfluegeSelectedReisetermine() {
      if (this.allSelectedRows.length <= 1) {
        return null;
      }

      const allInlandsfluege = this.allSelectedRows.reduce((acc, curr) => {
        const inlandsfluege = [];
        curr.data.metadata.inlandsfluege.forEach(inlandsflug => {
          inlandsfluege.push({ ...inlandsflug, reiseterminId: curr.data.id });
        });
        acc.push(inlandsfluege);
        return acc;
      }, []);

      if (allInlandsfluege.some(inlandsfluege => inlandsfluege === null || inlandsfluege.length === 0)) {
        return null;
      }
      const identicalInlandsfluege = allInlandsfluege.flat().reduce((acc, curr) => {
        const { tag, abflughafenIataCode, id, reiseterminId, flugnummer, zielflughafenIataCode } = curr;
        const existing = acc.find(
          item => item.abflughafenIataCode === abflughafenIataCode && item.tag === tag
        );
        if (existing) {
          existing.reiseterminId_inlandsflugId.push(`${reiseterminId}:${id}`);
          if (existing.flugnummer !== flugnummer) existing.flugnummer = null;
        } else {
          {
            acc.push({
              abflughafenIataCode,
              tag,
              reiseterminId_inlandsflugId: [`${reiseterminId}:${id}`],
              flugnummer,
              zielflughafenIataCode,
            });
          }
        }
        return acc;
      }, []);

      // check if the identical inlandsfluege are shared over each row
      const inlandsfluegeSharedOverEachRow = identicalInlandsfluege.filter(
        item => item.reiseterminId_inlandsflugId.length === this.allSelectedRows.length
      );
      return inlandsfluegeSharedOverEachRow;
    },
    isOnlyOneReiseSelected() {
      return (
        this.allSelectedRows.length > 1 &&
        new Set(this.allSelectedRows.map(row => row.data.reiseterminkuerzel.substring(0, 6))).size === 1
      );
    },
    regionsmanagerToId() {
      return Object.entries(regionsmanagerConfig).reduce((acc, curr) => {
        acc[curr[1].shortname] = curr[0];
        return acc;
      }, {});
    },
    idToRm() {
      return Object.entries(regionsmanagerConfig).reduce((acc, curr) => {
        acc[curr[0]] = curr[1].shortname;
        return acc;
      }, {});
    },
    regionsmanagersAllInfos() {
      return this.regionsmanagers
        .filter(rm => rm !== 'Alle')
        .reduce((acc, curr) => {
          acc[curr] = {
            id: this.regionsmanagerToId[curr],
            reisen: Object.keys(this.reisenOfRegionsmanagers).includes(this.regionsmanagerToId[curr])
              ? this.reisenOfRegionsmanagers[this.regionsmanagerToId[curr]]
              : [],
          };
          return acc;
        }, {});
    },
    reisekuerzelSelectedReisetermine() {
      if (this.isOnlyOneReiseSelected) {
        return this.allSelectedRows[0].data.reiseterminkuerzel.substring(0, 6);
      } else return null;
    },
    startdatumSelectedReisetermine() {
      if (this.isOnlyOneReiseSelected) {
        return this.allSelectedRows[0].data.abreisedatum;
      } else return null;
    },
    idsSelectedReisetermine() {
      if (this.allSelectedRows.length > 0) {
        return this.allSelectedRows.map(item => item.data.id);
      } else return [];
    },
    isOnlyOneReiseterminStateSelected() {
      return new Set(this.allSelectedRows.map(row => row.data.state)).size === 1;
    },
  },
  mounted() {},
  methods: {
    vorzeitigGarantiertClass,
    onHideEinkaufsdatenBearbeiten() {
      this.editOptionsBearbeitenModal = [
        {
          label: 'Status Gruppe',
          key: 'statusgruppe',
          type: 'select',
          selectOptions: statusgruppeOptions,
          value: null,
        },
        {
          label: 'Einkaufsart',
          key: 'einkaufsart',
          type: 'select',
          selectOptions: einkaufsartOptions,
          value: null,
        },
        { label: 'GRP PNR', key: 'gruppePnr', type: 'input', value: null },
        { label: 'Eingekauft PAX', key: 'eingekauftePax', type: 'number', value: null },
        { label: 'Mindestabnahme Airline', key: 'mindestabnahmeAirline', type: 'number', value: null },
        { label: 'Gruppe Flughafen', key: 'gruppeFlughafenIataCode', type: 'flughafen', value: null },
        { label: 'Flugnummer Gruppe Hin', key: 'flugnummerGruppeHin', type: 'input', value: null },
        { label: 'Flugnummer Gruppe Rück', key: 'flugnummerGruppeRueck', type: 'input', value: null },
        { label: 'OB Class', key: 'obClass', type: 'input', value: null },
        { label: 'IB Class', key: 'ibClass', type: 'input', value: null },
        { label: 'Optionsfrist', key: 'optionsfrist', type: 'number', value: null },
        { label: 'Ticketfrist', key: 'ticketfrist', type: 'number', value: null },
        { label: 'Hauptstrecke Preis Netto', key: 'hauptstreckePreisNetto', type: 'number', value: null },
        { label: 'Preis Inlandsflug', key: 'preisInlandsflug', type: 'number', value: null },
        { label: 'Taxes Flug', key: 'taxesFlug', type: 'number', value: null },
      ];
    },
    onHideTHWerteBearbeiten() {
      this.editOptionsTHWerteBearbeitenModal = [
        {
          label: 'TH Langstrecke',
          key: 'thLangstrecke',
          type: 'input',
          value: null,
        },
        {
          label: 'TH Inlandsflug',
          key: 'thInlandsflug',
          type: 'input',
          value: null,
        },
      ];
    },

    resetAllFilters() {
      this.gridApi.setFilterModel(null);
      const statusFilterComponent = this.gridApi.getFilterInstance('state');
      statusFilterComponent.setModel({
        values: this.defaultStatusFilter,
      });

      let filterDate1 = this.gridApi.getFilterInstance('abreisedatum');
      let filterDate2 = this.gridApi.getFilterInstance('startdatum');
      let filterDate3 = this.gridApi.getFilterInstance('enddatum');
      let filterDate4 = this.gridApi.getFilterInstance('optionsdatumFlugeinkauf');
      let filterDate5 = this.gridApi.getFilterInstance('ticketfristdatumFlugeinkauf');
      this.fullTextSearchInput = null;
      this.selectedRegions = [];
      this.prioritiesSelected = [];
      this.selectedRegionsmanager = [];
      this.vergangeneTermineAusblenden = true;
      this.odataFilterReiseterminkuerzel = null;
      this.odataFilterReisekuerzel = null;

      const query = { ...this.$route.query };
      delete query['reisetermine'];

      filterDate1.onDateRangeFilterChanged(null);
      filterDate2.onDateRangeFilterChanged(null);
      filterDate3.onDateRangeFilterChanged(null);
      filterDate4.onDateRangeFilterChanged(null);
      filterDate5.onDateRangeFilterChanged(null);
    },
    onSidebarHide() {
      this.activeReisetermin = null;
      this.gridApi.deselectAll();
    },
    onShowModalAufgabeEdit(prefixedVerlinkungen) {
      this.prefixedVerlinkungen = prefixedVerlinkungen;
      this.$bvModal.show('modalAufgabeEdit');
    },
    openAufgabenModalWithData() {
      this.prefixedVerlinkungen = this.allSelectedRows.map((item, i) => ({
        id: i,
        typ: 'Reisetermin',
        artifactId: item.data.id,
        artifactText: item.data.reiseterminkuerzel,
      }));

      this.$bvModal.show('modalAufgabeEdit');
    },
    onMultipleFlugnummernChanged(data) {
      const { flugnummer, reiseterminIdToInlandsflugId, inGruppeInkludiert } = data;
      this.gridApi.forEachNode(rowNode => {
        if (Object.keys(reiseterminIdToInlandsflugId).includes(rowNode.data.id.toString())) {
          const updated = rowNode.data;
          const updatedInlandsflug = updated.metadata.inlandsfluege.find(
            inlf => inlf.id === reiseterminIdToInlandsflugId[rowNode.data.id]
          );
          updatedInlandsflug.flugnummer = flugnummer;
          updatedInlandsflug.inGruppeInkludiert = inGruppeInkludiert;
          rowNode.updateData(updated);
        }
      });
    },
    onFlugnummerChange(data) {
      const { flugnummer, inGruppeInkludiert, reiseterminId, inlandsflugId } = data;
      this.gridApi.forEachNode(rowNode => {
        if (reiseterminId === rowNode.data.id) {
          const updated = rowNode.data;
          const updatedInlandsflug = updated.metadata.inlandsfluege.find(inlf => inlf.id === inlandsflugId);
          updatedInlandsflug.flugnummer = flugnummer;
          updatedInlandsflug.inGruppeInkludiert = inGruppeInkludiert;
          rowNode.updateData(updated);
          this.activeReisetermin = { ...rowNode.data };
        }
      });
    },
    onButtonInlandsfluegeBearbeiten() {
      this.$root.$emit('bv::toggle::modal', 'inlandsfluegeBearbeitenModal');
    },
    getParamsFlugeinkaufUpdate(selectedReisetermine, editOptions) {
      const keysToEdit = editOptions.map(item => item.key);
      const editDict = editOptions.reduce((acc, curr) => {
        const { value } = curr;
        acc[curr.key] = value;
        return acc;
      }, {});

      const allFlugeinkaufKeys = [
        'statusgruppe',
        'einkaufsart',
        'gruppePnr',
        'eingekauftePax',
        'mindestabnahmeAirline',
        'gruppeFlughafenIataCode',
        'flugnummerGruppeHin',
        'flugnummerGruppeRueck',
        'obClass',
        'ibClass',
        'optionsfrist',
        'ticketfrist',
        'hauptstreckePreisNetto',
        'inlandsflugPreis',
        'taxesFlug',
      ];
      const params = selectedReisetermine.reduce((accRt, currRt) => {
        accRt[currRt.startdatum] = allFlugeinkaufKeys.reduce((accKey, currKey) => {
          accKey[currKey] = keysToEdit.includes(currKey)
            ? editDict[currKey]
            : currRt.flugeinkauf?.[currKey] ?? null;
          return accKey;
        }, {});
        return accRt;
      }, {});
      return params;
    },
    getParamsThWerteUpdate(selectedReisetermine, editOptions) {
      const keysToEdit = editOptions.map(item => item.key);
      const editDict = editOptions.reduce((acc, curr) => {
        const { value } = curr;
        acc[curr.key] = value;
        return acc;
      }, {});
      const ThWerteKeys = ['thInlandsflug', 'thLangstrecke'];
      const params = selectedReisetermine.reduce((accRt, currRt) => {
        accRt[currRt.startdatum] = ThWerteKeys.reduce((accKey, currKey) => {
          accKey[currKey] = keysToEdit.includes(currKey)
            ? editDict[currKey]
            : currRt.flugeinkauf?.[currKey] ?? null;
          return accKey;
        }, {});
        return accRt;
      }, {});
      return params;
    },
    async onHandleSubmitReisetermineBearbeitenModal(editOptions) {
      const selectedReisetermine = this.allSelectedRows.map(item => item.data);
      const params = this.getParamsFlugeinkaufUpdate(selectedReisetermine, editOptions);

      this.isLoading = true;

      try {
        await apiService
          .put(`Reise/${this.reisekuerzelSelectedReisetermine}/reisetermine/metadata/flugeinkauf`, params)
          .then(res => {
            if (res.status === 200) {
              this.isLoading = false;
              this.toast('Flugeinkauf erfolgreich bearbeitet.');
              this.$root.$emit('bv::toggle::modal', 'EinkaufsdatenBearbeitenModal');
              this.onFlugplanungBearbeitet(res, false);
              this.gridApi.deselectAll();
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    async onHandleSubmitTHWerteBearbeitenModal(editOptions) {
      const selectedReisetermine = this.allSelectedRows.map(item => item.data);
      const params = this.getParamsThWerteUpdate(selectedReisetermine, editOptions);
      this.isLoading = true;

      try {
        await apiService
          .put(
            `Reise/${this.reisekuerzelSelectedReisetermine}/reisetermine/bulkUpdateReiseterminValues`,
            params
          )
          .then(res => {
            this.isLoading = false;
            this.toast('TH-Werte erfolgreich bearbeitet.');
            this.$root.$emit('bv::toggle::modal', 'ThWerteBearbeitenModal');
            this.onThWerteBearbeitet(res, false);
            this.gridApi.deselectAll();
          });
      } catch (error) {
        console.log(error);
      }
    },
    openMultipleBearbeitenModal() {
      this.$root.$emit('bv::show::modal', 'EinkaufsdatenBearbeitenModal');
    },
    openEditTHWertModal() {
      this.$root.$emit('bv::show::modal', 'ThWerteBearbeitenModal');
    },
    onFlugplanungBearbeitet(response, fromSidebar = true) {
      const value = response.data.result.value;

      this.gridApi.forEachNode(rowNode => {
        const matchingReiseterminkuerzel = Object.values(value).find(
          item => item.reiseterminkuerzel === rowNode.data.reiseterminkuerzel
        );
        if (matchingReiseterminkuerzel) {
          const updated = { ...rowNode.data };
          updated.flugeinkauf = { ...matchingReiseterminkuerzel.flugeinkauf };
          rowNode.updateData(updated);
          if (fromSidebar) {
            this.activeReisetermin = rowNode.data;
          }
        }
      });
    },
    onThWerteBearbeitet(response, fromSidebar = true) {
      const result = response.data.result;

      this.gridApi.forEachNode(rowNode => {
        // Durchlaufe die result-Daten und finde passende Einträge anhand des reiseterminkuerzel
        const matchingReiseterminkuerzel = result.find(
          item => item.reiseterminkuerzel === rowNode.data.reiseterminkuerzel
        );

        if (matchingReiseterminkuerzel) {
          const { thInlandsflug, thLangstrecke } = matchingReiseterminkuerzel;
          // überschreiben der werte
          let updated = { ...rowNode.data, thInlandsflug, thLangstrecke };
          rowNode.updateData(updated);
          if (fromSidebar) {
            this.activeReisetermin = updated;
          }
        }
      });
    },
    onReload() {
      this.gridApi.onFilterChanged();
    },
    changeRowSelection(direction) {
      const displayedRows = this.gridApi?.getDisplayedRowCount();
      if (direction === 'up') {
        if (this.selectedRowID !== 0) {
          const targetedRowID = this.selectedRowID - 1;
          this.gridApi.forEachNode(rowNode => {
            if (rowNode.rowIndex === targetedRowID) {
              rowNode.setSelected(true, true);
              this.gridApi.ensureIndexVisible(rowNode.rowIndex);
              this.activeReisetermin = rowNode.data;
              this.selectedRowID = targetedRowID;
            }
          });
        }
      } else if (direction === 'down') {
        if (this.selectedRowID + 1 < displayedRows) {
          const targetedRowID = this.selectedRowID + 1;
          this.gridApi.forEachNode(rowNode => {
            if (rowNode.rowIndex === targetedRowID) {
              rowNode.setSelected(true, true);
              this.gridApi.ensureIndexVisible(rowNode.rowIndex);
              this.activeReisetermin = rowNode.data;
              this.selectedRowID = targetedRowID;
            }
          });
        }
      }
    },
    handleEnterOnFullTextSearch($event) {
      // Add filter to ag grid filter model
      const { value } = $event.target;
      const fullTextSearchFilterInstance = this.gridApi.getFilterInstance('fullTextSearch');
      fullTextSearchFilterInstance.setModel({
        filter: value,
        type: 'contains',
      });
      this.gridApi.onFilterChanged();
    },
    onRowDoubleClicked(params) {
      this.gridApi.deselectAll();
      this.gridApi.forEachNode(rowNode => {
        if (rowNode.rowIndex === params.rowIndex) {
          rowNode.setSelected(true);
          this.selectedRowID = params.rowIndex;
        }
      });
      this.activeReisetermin = params.data;
      this.$root.$emit('bv::toggle::collapse', 'sidebar-flugplanung');
    },

    createRowData(rt) {
      const exportData = [];

      const { metadata, gueltigerSaisonpreis } = rt;
      const ziellandZielflughaefen = metadata.ziellandZielflughaefen
        .map(item => item.flughafenIataCode)
        .join(', ');
      const ziellandAbflughaefen = metadata.ziellandAbflughaefen
        .map(item => item.flughafenIataCode)
        .join(', ');

      const flightArrival = add(parseISO(rt.abreisedatum), {
        days: rt.metadata.overnightOutbound || 0,
      });
      const gesamtpreis =
        (
          (rt.flugeinkauf?.hauptstreckePreisNetto ?? 0) +
          (rt.flugeinkauf?.inlandsflugPreis ?? 0) +
          (rt.flugeinkauf?.taxesFlug ?? 0)
        )
          .toString()
          .replace('.', ',') + ' €';
      const einkaufsart = einkaufsartOptions.find(item => item.value === rt.flugeinkauf?.einkaufsart)?.text;
      const hotelendUndRueckflugdatum = formatDateWithoutHours(rt.hotelendUndRueckflugdatum);
      const status = rt.state;
      const statusgruppe = statusgruppeOptions.find(
        item => item.value === rt.flugeinkauf?.statusgruppe
      )?.text;
      const abreisedatum = formatDateWithoutHours(rt.abreisedatum);
      const startHLeistung = formatDateWithoutHours(rt.startdatum);
      const fruehesteAnkunft = metadata.fruehesteAnkunft?.substring(0, 5)
        ? format(new Date(flightArrival), 'dd.MM.yyyy') + ' ' + metadata.fruehesteAnkunft?.substring(0, 5)
        : '';
      const spaetesteAnkunft = metadata.spaetesteAnkunft?.substring(0, 5)
        ? format(
            add(new Date(flightArrival), { days: metadata.restriktionenHinRollover ? 1 : 0 }),
            'dd.MM.yyyy'
          ) +
          ' ' +
          metadata.spaetesteAnkunft?.substring(0, 5)
        : '';
      const fruehesterAbflug = metadata.fruehesterAbflug?.substring(0, 5)
        ? format(new Date(rt.hotelendUndRueckflugdatum), 'dd.MM.yyyy') +
          ' ' +
          metadata.fruehesterAbflug?.substring(0, 5)
        : '';
      const spaetesterAbflug = metadata.spaetesterAbflug?.substring(0, 5)
        ? format(
            add(new Date(rt.hotelendUndRueckflugdatum), {
              days: metadata.restriktionenRueckRollover ? 1 : 0,
            }),
            'dd.MM.yyyy'
          ) +
          ' ' +
          metadata.spaetesterAbflug?.substring(0, 5)
        : '';
      const ankunftDe = formatDateWithoutHours(rt.enddatum);
      const ticketdatum = rt.flugeinkauf?.ticketfrist
        ? format(
            sub(new Date(rt.abreisedatum), {
              days: rt.flugeinkauf?.ticketfrist,
            }),
            'dd.MM.yyyy'
          )
        : '';
      const optionsdatum = rt.flugeinkauf?.optionsfrist
        ? format(
            sub(new Date(rt.abreisedatum), {
              days: rt.flugeinkauf?.optionsfrist,
            }),
            'dd.MM.yyyy'
          )
        : '';
      const vorzeitigGarantiert = value => {
        return value === 'VorzeitigGarantiert'
          ? 'Vorzeitig garantiert'
          : value === 'WarVorzeitigGarantiert'
          ? 'War vorzeitig garantiert'
          : value === 'NichtVorzeitigGarantiert'
          ? 'Nicht vorzeitig garantiert'
          : value;
      };

      exportData.push([
        rt.istZusatztermin === true ? 'Zusatztermin' : 'Kein Zusatztermin',
        rt.metadata?.inlandsfluegeEinkauf,
        rt.reiseterminkuerzel,
        rt.garantiert === true ? 'Ja' : 'Nein',
        vorzeitigGarantiert(rt.vorzeitigGarantiert),
        rt.pax?.ist,
        rt.metadata?.erwartetePax,
        abreisedatum,
        hotelendUndRueckflugdatum,
        ziellandZielflughaefen,
        ziellandAbflughaefen,
        '',
        '',
        status,
        statusgruppe,
        einkaufsart,
        rt.stopSale === true ? 'Ja' : 'Nein',
        'Main',
        rt.metadata?.overnightOutbound,
        rt.metadata?.overnightInbound,
        startHLeistung,
        rt.flugeinkauf?.gruppePnr ?? '',
        rt.flugeinkauf?.flugnummerGruppeHin,
        rt.verkehrstagHin ?? '',
        fruehesteAnkunft,
        spaetesteAnkunft,
        rt.flugeinkauf?.flugnummerGruppeRueck,
        rt.verkehrstagRueck ?? '',
        fruehesterAbflug,
        spaetesterAbflug,
        ankunftDe,
        rt.metadata?.transferszenarioHin ?? '',
        rt.metadata?.transferszenarioRueck ?? '',
        rt.hauptstreckePreisNetto ? `${rt.hauptstreckePreisNetto}€` : '',
        rt.flugeinkauf?.inlandsflugPreis ? `${rt.flugeinkauf?.inlandsflugPreis}€` : '',
        rt.flugeinkauf?.taxesFlug ? `${rt.flugeinkauf?.taxesFlug}€` : '',
        gesamtpreis,
        rt.thLangstrecke ? `${rt.thLangstrecke}€` : '',
        rt.thInlandsflug ? `${rt.thInlandsflug}€` : '',
        gueltigerSaisonpreis?.thMaxLangstrecke ? `${gueltigerSaisonpreis?.thMaxLangstrecke}€` : '',
        gueltigerSaisonpreis?.thMaxInlandsflug ? `${gueltigerSaisonpreis?.thMaxInlandsflug}€` : '',
        ticketdatum,
        optionsdatum,
      ]);
      metadata.inlandsfluege
        ?.sort((a, b) => a.tag - b.tag)
        .forEach((inlf, i) => {
          const dateOfInlandsflug = add(new Date(rt.abreisedatum), { days: +inlf.tag - 1 });
          const verkehrstag = getISODay(dateOfInlandsflug);
          const zlZielflughaefenInland = inlf.zielflughafenIataCode;
          const zlAbflughaefenInland = inlf.abflughafenIataCode;
          exportData.push([
            '',
            '',
            `${rt.reiseterminkuerzel}_${i + 1}`,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            formatDateWithoutHours(dateOfInlandsflug), // Start H-Leistung
            zlAbflughaefenInland + ' - ' + zlZielflughaefenInland, //
            '',
            '',
            inlf.einkauf,
            '',
            'INLF',
            '',
            '',
            '', //
            '',
            inlf.flugnummer ?? '',
            verkehrstag, // hinflug VT
            '',
            inlf.spaetesteAnkunft?.substring(0, 5) ?? '', // späteste Ankunft
            '',
            '',
            inlf.fruehesterAbflug?.substring(0, 5) ?? '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
          ]);
        });

      return exportData;
    },

    async fetchReiseterminData() {
      return await odataService.getReisetermin({
        filter: this.lastOdataFilter,
        select: flugplanungSelectedReiseterminVariables,
        count: true,
        expand: flugplanungExpandConfig,
        orderBy: 'startdatum asc',
      });
    },

    createExcelWorkbook(exportData) {
      const wb = utils.book_new();
      const ws = utils.aoa_to_sheet(exportData);
      utils.book_append_sheet(wb, ws, 'flugplanung_Export');
      return wb;
    },

    generateFileName() {
      return `flugplanung_export_${format(new Date(), 'dd-MM-yy_HH:mm')}.xlsx`;
    },

    async exportData() {
      try {
        const exportData = [flugplanungHeaderRow];
        const response = await this.fetchReiseterminData();

        response.data.forEach(rt => {
          const rowData = this.createRowData(rt);
          exportData.push(...rowData);
        });

        const wb = this.createExcelWorkbook(exportData);
        writeFileXLSX(wb, this.generateFileName());
      } catch (error) {
        console.error('Export failed: ', error);
      }
    },
    async onButtonExport() {
      await this.exportData();
    },
    onRowSelected() {
      this.allSelectedRows = this.getAllSelectedRows();
      const statusBarComponent = this.gridApi?.getStatusPanel('statusBarCompKey');
      statusBarComponent?.setMarkedCount(this.allSelectedRows.length);
    },
    getAllSelectedRows() {
      const rowsSelected = [];
      this.gridApi.forEachNode((node, index) => {
        if (node.selected) {
          rowsSelected.push(node);
        }
      });
      return rowsSelected;
    },
    updateStatusBar(displayedRowsCount) {
      const statusBarComponent = this.gridApi?.getStatusPanel('statusBarCompKey');
      statusBarComponent?.setRowCount(displayedRowsCount);
      statusBarComponent?.setOdataCount(this.count);
    },
    changeRegionFilterInstance(selectedRegions) {
      if (!this.gridApi) return;

      const regionsFilter = this.gridApi.getFilterInstance('reise.region');
      if (!regionsFilter) return;

      if (selectedRegions.length > 0) {
        regionsFilter.setModel({ values: selectedRegions });
      } else {
        regionsFilter.setModel({});
      }
      this.gridApi.onFilterChanged();
    },

    onPriorityClick($event) {
      const { id: priorityText } = $event.target;
      if (priorityText === 'Alle') {
        this.prioritiesSelected = [];
      } else if (!this.prioritiesSelected.includes(priorityText)) {
        this.prioritiesSelected.push(priorityText);
      } else {
        this.prioritiesSelected = this.prioritiesSelected.filter(priority => priority !== priorityText);
      }
      // Wenn alle angeklickt sind, resette
      if (this.prioritiesSelected.length === this.priorities.length - 1) {
        this.prioritiesSelected = [];
      }

      this.gridApi.onFilterChanged();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      const statusFilterComponent = this.gridApi.getFilterInstance('state');
      statusFilterComponent.setModel({
        values: this.defaultStatusFilter,
      });
      console.log('von dem link: ', this.$route.query);

      if (this.$route.query.reiseterminkuerzel) {
        this.odataFilterReiseterminkuerzel = `reiseterminkuerzel in (${this.$route.query.reiseterminkuerzel
          .split(',')
          .map(rt => `'${rt}'`)
          .join(',')})`;
      } else if (this.$route.query.reisetermine) {
        this.odataFilterReisekuerzel = `reise/reisekuerzel in (${this.$route.query.reisetermine
          .split(',')
          .map(rt => `'${rt}'`)
          .join(',')})`;
      }

      this.gridApi?.onFilterChanged();

      const updateData = () => {
        const server = this.server();
        const datasource = this.createDatasource(server);
        params.api.setServerSideDatasource(datasource);
      };
      updateData();
    },
    createDatasource(server) {
      return {
        getRows: async params => {
          console.log('[Datasource] - rows requested by grid: ', params.request);
          const response = await server.getData(params.request);
          if (response.success) {
            params.success({
              rowData: response.rows,
            });
            this.updateStatusBar(this.gridApi?.getDisplayedRowCount());
          } else {
            params.fail();
          }
        },
      };
    },
    server() {
      return {
        getData: async request => {
          this.checkIfFilterSupplied();
          const odataFilter = this.getOdataFilterFromAgGridRequest(request);
          this.lastOdataFilter = odataFilter;

          const selectedReiseterminVariables = [...flugplanungSelectedReiseterminVariables, 'id'];

          const expand = {
            ...flugplanungExpandConfig,
            aufgaben: {},
          };

          const response = await odataService.getReisetermin({
            filter: odataFilter,
            top: request.endRow - request.startRow,
            select: selectedReiseterminVariables,
            skip: request.startRow,
            count: true,
            expand,
            ...(request.sortModel.length > 0
              ? {
                  orderBy: request.sortModel.map(s => s.colId.replaceAll('.', '/') + ' ' + s.sort),
                }
              : { orderBy: 'reise/prioritaet asc, reise/reisekuerzel asc, abreisedatum asc' }),
          });
          this.count = response.count;
          this.regionFilterDisabled = false;

          const filterField = [
            'reiseterminkuerzel',
            'ziellandZielflughaefen',
            'ziellandAbflughaefen',
            'verkehrstagHin',
            'verkehrstagRueck',
            'flugeinkauf.statusgruppe',
            'flugeinkauf.einkaufsart',
            'metadata.transferszenarioHin',
            'metadata.transferszenarioRueck',
          ];
          filterField.forEach(field => {
            const filterInstance = this.gridApi.getFilterInstance(field);
            if (filterInstance) {
              filterInstance.refreshFilterValues();
            }
          });

          // zl
          return {
            success: true,
            rows: response.data,
          };
        },
      };
    },
    getOdataFilterFromAgGridRequest(request) {
      const filterKeys = Object.keys(request.filterModel);
      let filters = [];

      const allFilterKeys = filterKeys.map(key => {
        const formattedKey = key.replaceAll('.', '/');
        if (request.filterModel[key].filterType === 'text') {
          if (
            [
              'pax/ist',
              'thInlandsflug',
              'thLangstrecke',
              'gueltigerSaisonpreis/thMaxLangstrecke',
              'gueltigerSaisonpreis/thMaxInlandsflug',
              'flugeinkauf/hauptstreckePreisNetto',
              'flugeinkauf/inlandsflugPreis',
              'flugeinkauf/taxesFlug',
              'flugeinkauf/gesamtpreisFlugeinkauf',
            ].includes(formattedKey)
          ) {
            if (request.filterModel[key].filter === '-') {
              return { [formattedKey]: null };
            } else if (request.filterModel[key].filter.includes('-')) {
              const min = parseInt(request.filterModel[key].filter.split('-')[0]);
              const max = parseInt(request.filterModel[key].filter.split('-')[1]);
              return { [formattedKey]: { le: max, ge: min } };
            } else if (request.filterModel[key].filter[0] === '>') {
              const min = parseInt(request.filterModel[key].filter.split('>')[1]);
              return { [formattedKey]: { gt: min } };
            } else if (request.filterModel[key].filter[0] === '<') {
              const max = parseInt(request.filterModel[key].filter.split('<')[1]);
              return { [formattedKey]: { lt: max } };
            } else return { [formattedKey]: parseInt(request.filterModel[key].filter) };
          } else if (['flugeinkauf/gruppePnr'].includes(formattedKey)) {
            if (request.filterModel[key].filter === '-') {
              return { [formattedKey]: null };
            }
          } else if (key === 'fullTextSearch') {
            const searchText = request.filterModel[key].filter;
            let fullTextFilters = {
              comments: {
                any: {
                  text: { contains: searchText },
                },
              },
            };
            return fullTextFilters;
          }

          return { [formattedKey]: { startswith: request.filterModel[key].filter } };
        } else if (request.filterModel[key].filterType === 'set') {
          if (key === 'reiseterminkuerzel') {
            return { 'reise/reisekuerzel': { in: request.filterModel[key].values } };
          } else if (key === 'reise.region') {
            const regionValues = request.filterModel[key].values
              .map(value => (value === 'Fern' ? fernRegions : euRegions.filter(region => region != 'Dach')))
              .flat();
            return { [formattedKey]: { in: regionValues } };
          } else if (key === 'inlandsflugEinkauf') {
            const odataFlugeinkaufFormatter = {
              Gemischt:
                "metadata/inlandsfluege/any(inlandsfluege:inlandsfluege/einkauf eq 'Dmc') and metadata/inlandsfluege/any(inlandsfluege:inlandsfluege/einkauf eq 'Skr') and metadata/inlandsfluege/any()",
              NurSkr:
                "metadata/inlandsfluege/all(inlandsfluege:inlandsfluege/einkauf eq 'Skr') and metadata/inlandsfluege/any()",
              NurDmc:
                "metadata/inlandsfluege/all(inlandsfluege:inlandsfluege/einkauf eq 'Dmc') and metadata/inlandsfluege/any()",
              KeinInlandsflug: 'not Metadata/inlandsfluege/any()',
            };
            return (
              '(' +
              request.filterModel[key].values.map(value => odataFlugeinkaufFormatter[value]).join(' or ') +
              ')'
            );
          } else if (key === 'ziellandZielflughaefen' || key === 'ziellandAbflughaefen') {
            const { values } = request.filterModel[key];
            const formattedValues = values.map(flughafen => `'${flughafen}'`).join(',');
            const filterString = `metadata/${key}/any(flughafen: flughafen/flughafenIataCode in (${formattedValues}))`;
            return filterString;
          } else if (key === 'flugeinkauf.statusgruppe' || key === 'flugeinkauf.einkaufsart') {
            const { values } = request.filterModel[key];
            if (
              // je nachdem welcher Key wird das jeweilge Options benutzt
              key === 'flugeinkauf.statusgruppe'
                ? values.length === statusgruppeOptions.length
                : values.length === einkaufsartOptions.length
            ) {
              // es sind alle optionen angeklickt, dann braucht man keinen Filter
              return null;
            } else if (values.includes(null)) {
              // es wurden neben '-' noch weiter Filter angeklickt
              const otherVAluesThanNull = values.filter(value => value !== null);
              return {
                // der erste Teil ist, wenn man nur null ausgewählt hat
                or: [{ [formattedKey]: null }, { [formattedKey]: { in: otherVAluesThanNull } }],
              };
              // wenn eine != null option ausgewählt wurde
            } else {
              return {
                [formattedKey]: { in: values },
              };
            }
          } else if (formattedKey === 'aufgaben') {
            // die lesbaren Werte werden in die internen Werte konvertiert
            const values = request.filterModel[key].values.map(label => this.aufgabenLabelToValueMap[label]);
            // Gib mir alle Aufgaben, bei denen die Kategorie (a/kategorie) gleich (eq) diesem speziellen Wert (value) ist.
            // wenn select all
            if (values.length === 2) {
              return;
            }

            if (values.length === 1 && values[0] === 'nichtOptionsfristErreicht') {
              return `aufgaben/all(aufgabe: aufgabe/kategorie ne 'OptionsfristErreicht')`;
            }

            const categoriesFilter = values.map(value => `aufgabe/kategorie eq '${value}'`);
            // O-Data Abfrage, damit nur die Zeilen mit den richtigen Lables angezeigt werden
            return `aufgaben/any(aufgabe: ${categoriesFilter})`;
          } else {
            return { [formattedKey]: { in: request.filterModel[key].values } };
          }
        } else if (request.filterModel[key].filterType === 'date') {
          const dateFrom = new Date(request.filterModel[key].dateFrom.substring(0, 10));
          const dateTo = add(new Date(request.filterModel[key].dateTo), { hours: 23, minutes: 59 });

          return {
            [formattedKey]: {
              ge: dateFrom,
              le: dateTo,
            },
          };
        }
      });
      if (!filterKeys.includes('state')) {
        filters = [...filters, { state: { in: this.defaultStatusFilter } }];
      }
      filters = [
        ...filters,
        {
          isDeleted: { ne: true },
          'reise/region': { ne: 'Dach' },
        },
        ...allFilterKeys,
      ];

      if (this.vergangeneTermineAusblenden) {
        filters = [...filters, { abreisedatum: { ge: new Date() } }];
      }

      if (this.prioritiesSelected.length > 0) {
        filters = [
          ...filters,
          {
            ['reise/prioritaet']: {
              in: this.prioritiesSelected.map(
                prioritaet => this.priorities.find(p => p.text === prioritaet)?.value
              ),
            },
          },
        ];
      }

      if (this.selectedRegionsmanager.length > 0) {
        const reisenOfSelectedRms = this.selectedRegionsmanager
          .map(rm => this.regionsmanagersAllInfos[rm].reisen)
          .flat();

        filters = [...filters, { ['reise/reisekuerzel']: { in: reisenOfSelectedRms } }];
      }

      if (this.odataFilterReiseterminkuerzel) {
        filters = [...filters, this.odataFilterReiseterminkuerzel];
      }
      if (this.odataFilterReisekuerzel) {
        filters = [...filters, this.odataFilterReisekuerzel];
      }

      return filters;
    },
    checkIfFilterSupplied() {
      const usedFilters = Object.keys(this.gridApi?.getFilterModel()).filter(item => item !== 'state');
      const statusFilterValues = this.gridApi.getFilterInstance('state')?.appliedModel?.values;
      const defaultStatusFilterDiffers =
        statusFilterValues &&
        (statusFilterValues.length !== 6 ||
          !statusFilterValues.includes('Vorgeschlagen') ||
          !statusFilterValues.includes('Aufgelegt') ||
          !statusFilterValues.includes('Anlagebereit') ||
          !statusFilterValues.includes('CmBestaetigt') ||
          !statusFilterValues.includes('CmAngefragt') ||
          !statusFilterValues.includes('Abgesagt'));

      this.isFilterSupplied =
        this.gridApi &&
        (this.regionSelected != null ||
          !this.vergangeneTermineAusblenden ||
          this.prioritiesSelected.length > 0 ||
          this.selectedRegionsmanager.length > 0 ||
          this.odataFilterReiseterminkuerzel !== null ||
          this.odataFilterReisekuerzel !== null ||
          usedFilters.length > 0 ||
          defaultStatusFilterDiffers);
    },
  },
};
</script>
<style scoped>
:deep(.ag-theme-alpine) {
  font-family: 'Poppins';
}
:deep(.ag-theme-alpine .ag-header-cell) {
  font-weight: 500;
  font-size: 14px;
}
:deep(.ag-cell),
:deep(.ag-header-cell) {
  padding: 0px 0.5rem;
}
:deep(.hinflug-header) {
  background-color: #aab7b876;
}
:deep(.rueckflug-header) {
  background-color: #515a5a76;
}
:deep(.dots-dropdown) {
  font-weight: 600;
  font-size: 14px;
}
</style>
